/* You can add global styles to this file, and also import other style files */

html {
    height: 100%;
    overflow-x: hidden;
}

body {
    height: 100%;
    font-size: 14px;
    line-height: 1.42857143;
    background: #232828;
    color: #ffffff;
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-weight: 300;
    overflow-x: hidden;
    /* prevent horizontal scrollbar from appearing */
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 400;
}

h1,
h2 {
    font-weight: 700;
    text-align: center;
    font-size: 40px;
}

a {
    color: #0362A7;
}

a:hover {
    color: #0362A7;
}

.greek-spot-logo {
    width: 120px;
    border-radius: 100%;
    transform: translate(-30px, -5px)
}

.selected-nav-bar {
    color: #0362A7 !important;
}

.selector-language {
    color: #0362A7;
    width: 45px;
}

.themeColor {
    color: #0362A7;
}


/* start h2 */

h2 {
    text-align: center;
    font-size: 40px;
    /* padding-bottom: 40px; */
}

h2 span,
h3 span {
    color: #0362A7;
    font-weight: bold;
}

h4 {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.text-center {
    text-align: center;
}

p {
    margin: 0 0 10px;
}


.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.generic-dialog {
    text-align: center;
    overflow: hidden !important;

    .mat-dialog-container {
        background-color: transparent !important;
        box-shadow: initial !important;
    }
}

.g-recaptcha >  div{
    text-align: center;
    width: auto !important;
    height: auto !important;
   }